import { useCombobox } from 'downshift';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppsFlyer } from '@/core/hooks/useAppsFlyer';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { transformQueryStringToString } from '@/core/transformers/transformQueryStringToString';
import { useSearchDefaultsQuery } from '@/modules/product/queries/SearchDefaults.delio.generated';
import { isTruthy } from '@lib/api/is-truthy';
import { useRouterAction } from '@lib/router/hooks/useRouterAction';

import { useDebouncedProductSearchQuery } from '../../../hooks/useDebouncedProductSearchQuery';

type UseProductSearchProps = {
  onIsOpenChange?: (isOpen?: boolean) => void;
};

const { stateChangeTypes } = useCombobox;

export const useProductSearch = ({ onIsOpenChange }: UseProductSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { pathname, query } = useRouter();
  const [pushStatus, push] = useRouterAction('push');
  const { coordinates, loading } = useUserAddressCoordinates();
  const searchFromUrl = transformQueryStringToString(
    query.q || query.searchPhrase
  );
  const { data, error } = useSearchDefaultsQuery({
    variables: { coordinates },
    skip: loading,
  });

  const [inputValue, setInputValue] = useState(searchFromUrl);
  const debouncedProductSearch = useDebouncedProductSearchQuery(inputValue);
  const { attributionToken } = debouncedProductSearch;

  const pushToSearchResults = async () => {
    await push(
      addQueryToHref(inputValue, '/search', attributionToken),
      undefined,
      {
        shallow: true,
        scroll: true,
      }
    );

    // Always trigger the blur event after an async action.
    // If you call it too soon, the search modal won't close.
    inputRef.current?.blur();
  };

  const pushToProductPage = async (selectedIndex?: number) => {
    const highlightedProduct = suggestedProducts?.find(
      (_, index) => index === highlightedIndex || index === selectedIndex
    );

    if (!highlightedProduct) return;

    const href = ['/products', highlightedProduct.slug]
      .filter(Boolean)
      .join('/');

    await push(addQueryToHref(inputValue, href, attributionToken), undefined, {
      shallow: true,
      scroll: true,
    });

    // Always trigger the blur event after an async action.
    // If you call it too soon, the search modal won't close.
    inputRef.current?.blur();
  };

  const total = debouncedProductSearch?.total;

  const suggestedProducts =
    debouncedProductSearch.products ?? data?.searchDefaults?.products;

  const items =
    suggestedProducts && suggestedProducts.length !== 0
      ? suggestedProducts.filter(isTruthy).map((eachProduct) => ({
          key: eachProduct.id,
          label: eachProduct.name,
          value: eachProduct.slug ?? '',
          type: 'product',
        }))
      : [];

  const isEmpty =
    Array.isArray(suggestedProducts) &&
    !suggestedProducts.length &&
    !debouncedProductSearch.isLoading;

  const {
    isOpen,
    highlightedIndex,
    closeMenu,
    openMenu,
    getLabelProps,
    getMenuProps,
    getInputProps,
    reset,
    setInputValue: setDownshiftInputValue,
  } = useCombobox({
    items,
    initialInputValue: searchFromUrl,
    onInputValueChange: ({ inputValue: value }) =>
      setInputValue((value ?? '').trim()),
    onSelectedItemChange: async (changes) => {
      if (changes.selectedItem?.type !== 'product') return;
      await pushToProductPage();
    },
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        // Disable mouse handling here, we're going to handle
        case stateChangeTypes.ItemMouseMove:
        case stateChangeTypes.ItemClick: {
          return state;
        }

        case stateChangeTypes.FunctionSelectItem:
        case stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem?.type !== 'query') {
            return {
              ...state,
              selectedItem: changes.selectedItem,
              highlightedIndex: changes.highlightedIndex,
            };
          }

          return {
            ...state,
            inputValue: changes.inputValue,
            selectedItem: changes.selectedItem,
            highlightedIndex: -1,
          };

        case stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: pathname.includes('search?q=') ? inputValue : '',
            selectedItem: null,
          };

        case stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true,
          };

        case stateChangeTypes.FunctionSetInputValue:
          inputRef?.current?.focus();
          return {
            ...changes,
          };

        default:
          return changes;
      }
    },

    onIsOpenChange: async (changes) => {
      onIsOpenChange?.(changes.isOpen);

      await removeAppsFlyerQueryParam();
    },
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const openSearchOnAppsFlyerUrl = useCallback(() => {
    openMenu();
    inputRef.current?.focus();
  }, [inputRef, openMenu]);

  const { removeAppsFlyerQueryParam } = useAppsFlyer({
    appsFlyerQueryParam: 'searchPhrase',
    enabled: !isOpen,
    onSuccess: openSearchOnAppsFlyerUrl,
  });

  const inputProps = getInputProps({
    onFocus: openMenu,
    onKeyDown: async (e) => {
      if (e.key !== 'Enter') return;
      if (pushStatus === 'LOADING') return;

      if (highlightedIndex !== -1) {
        return pushToProductPage();
      }

      return pushToSearchResults();
    },
    ref: inputRef,
  });

  const inputAriaAttributes = {
    'aria-autocomplete': inputProps['aria-autocomplete'],
    'aria-controls': inputProps['aria-controls'],
    'aria-labelledby': inputProps['aria-labelledby'],
    autoComplete: inputProps.autoComplete,
  };

  const menuProps = getMenuProps();
  const labelProps = getLabelProps();

  return {
    combobox: {
      inputProps,
      inputAriaAttributes,
      menuProps,
      labelProps,
      isOpen,
      reset,
      closeMenu,
      setInputValue: setDownshiftInputValue,
    },
    comboboxItems: items,
    suggestedProducts,
    pushToSearchResults,
    pushToSearchResultsStatus: pushStatus,
    isLoading: debouncedProductSearch.isLoading,
    isErrored: debouncedProductSearch.isErrored || Boolean(error),
    isInputValueLongEnough: debouncedProductSearch.isInputValueLongEnough,
    pushToProductPage,
    isEmpty,
    attributionToken: attributionToken ?? undefined,
    total,
  };
};

const addQueryToHref = (
  query: string,
  href: string,
  attributionToken?: string | null
) =>
  attributionToken
    ? `${href}?q=${encodeURI(query)}&attributionToken=${attributionToken}`
    : `${href}?q=${encodeURI(query)}`;
