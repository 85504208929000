import dynamic from 'next/dynamic';

import { mobileAppUrls } from '@/core/constants/appUrls';

const AppStoreBadgeSvg = dynamic(async () => import('./app-store-badge.svg'), {
  ssr: false,
});

export const AppStoreBadge = ({ height }: { height: number }) => (
  <a
    href={mobileAppUrls.iphone}
    target="_blank"
    rel="noreferrer"
    aria-label="App Store"
  >
    <AppStoreBadgeSvg height={height} />
  </a>
);
