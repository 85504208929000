import dynamic from 'next/dynamic';

import { mobileAppUrls } from '@/core/constants/appUrls';

const GooglePlayBadgeSvg = dynamic(
  async () => import('./google-play-badge.svg'),
  { ssr: false }
);

export const GooglePlayBadge = ({ height }: { height: number }) => (
  <a
    href={mobileAppUrls.android}
    target="_blank"
    rel="noreferrer"
    aria-label="Google Play"
  >
    <GooglePlayBadgeSvg height={height} />
  </a>
);
