import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MouseEvent } from 'react';

import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { cn } from '@/core/ui/utils';
import {
  CART_SIDEBAR_STATE,
  useCreateCartSidebarUrl,
} from '@/modules/cart/hooks/useCreateCartSidebarUrl';
import { useLocationModal } from '@/modules/location/hooks/useLocationModal';

import { useCartViewedEventHandler } from '../../../../hooks/useCartViewedEventHandler';
import BagIcon from '../../../../images/bag-icon.svg';
import { IconButton } from '../../../IconButton/IconButton';

export const YourCartLink = () => {
  const { t } = useTranslation('layout');
  const { query, push } = useRouter();
  const { openLocationModal } = useLocationModal();
  const isCartSidebarOpen = query.cartSidebar === CART_SIDEBAR_STATE.OPEN;
  const shippingAddress = useShippingAddress();
  const openCartIconUrl = useCreateCartSidebarUrl(CART_SIDEBAR_STATE.OPEN);
  const handleCartViewedEvent = useCartViewedEventHandler();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (shippingAddress.data?.city) {
      handleCartViewedEvent();
      return push(openCartIconUrl, undefined, { shallow: true });
    }

    e.preventDefault();
    openLocationModal();
  };

  return (
    <span
      className={cn(
        isCartSidebarOpen && 'pointer-events-none',
        'whitespace-nowrap'
      )}
    >
      <IconButton onClick={onClick} className="xl:w-auto">
        <BagIcon className="size-6" />
        <p className="hidden whitespace-nowrap text-sm font-medium xl:block">
          {t('Your cart')}
        </p>
      </IconButton>
    </span>
  );
};
