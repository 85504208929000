import Link from 'next/link';
import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const FooterListItem = ({
  url,
  children,
  locale,
  openInNewTab,
}: {
  url: string;
  children: ReactNode;
  locale?: string | false;
  openInNewTab?: true;
}) => (
  <li>
    <Link
      href={url}
      locale={locale}
      className={cn('font-medium')}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {children}
    </Link>
  </li>
);
