import { FC, memo } from 'react';

import { FormattedNetContain } from '@/core/ui/components/FormattedNetContain/FormattedNetContain';
import { ProductImage } from '@/core/ui/components/ProductImage/ProductImage';
import { ProductMultibuyBadge } from '@/core/ui/components/ProductMultibuyBadge/ProductMultibuyBadge';
import { cn } from '@/core/ui/utils';
import { ProductCounter } from '@/modules/product/components/ProductCounter/ProductCounter';

import { ProductSearchSuggestedProductBadges } from './components/ProductSearchSuggestedProductBadges/ProductSearchSuggestedProductBadges';
import { ProductSearchSuggestedProductPrice } from './components/ProductSearchSuggestedProductPrice/ProductSearchSuggestedProductPrice';
import { useProductSearchSuggestedProduct } from './hooks/useProductSearchSuggestedProduct';

type SuggestedProductProps = {
  index: number;
};

export const ProductSearchSuggestedProduct: FC<SuggestedProductProps> = memo(
  ({ index }) => {
    const {
      hasBadges,
      imageUrl,
      isOnStock,
      product,
      pushToProductPage,
      attributionToken,
      discount,
      pricePercentageDifference,
    } = useProductSearchSuggestedProduct(index);

    if (!product) return null;

    return (
      <li
        className={cn(
          'h-[88px]',
          'border',
          'border-border-subtle',
          'rounded-lg'
        )}
      >
        <div className={cn('flex', 'items-center', 'relative', 'h-full')}>
          <button
            onClick={() => pushToProductPage(index)}
            className={cn(
              'flex',
              'w-full',
              'pt-3',
              'pb-2',
              !isOnStock && cn('', 'grayscale')
            )}
            type="button"
          >
            <div
              className={cn('min-w-12', 'h-12', 'mr-3', 'ml-4', 'self-center')}
            >
              <ProductImage alt={product.name} url={imageUrl} />
            </div>
            <div
              className={cn(
                'flex',
                'flex-col',
                'w-full',
                'self-center',
                'mr-2'
              )}
            >
              <span
                className={cn(
                  'line-clamp-2',
                  'text-body-lg',
                  'font-medium',
                  'text-left'
                )}
              >
                {product.name}
              </span>
              <div
                className={cn(
                  'flex',
                  'text-text-subtlest',
                  'text-body-sm',
                  'items-center',
                  hasBadges ? 'mt-1' : 'mt-2'
                )}
              >
                <FormattedNetContain
                  containUnit={product.attributes.contain_unit}
                  netContain={product.attributes.net_contain}
                />
                <div className={cn('flex', 'gap-x-1.5', 'ml-2')}>
                  {isOnStock && product?.price?.multibuy?.description && (
                    <ProductMultibuyBadge
                      variant={
                        product?.price?.multibuy?.maxQuantity
                          ? 'secondary'
                          : 'primary'
                      }
                      description={product.price.multibuy.description}
                    />
                  )}
                  <ProductSearchSuggestedProductBadges
                    priceDifference={pricePercentageDifference}
                    tags={product.attributes.tags}
                  />
                </div>
              </div>
            </div>
            <div>
              <ProductSearchSuggestedProductPrice
                discount={discount}
                productPrice={product?.price?.value}
              />
            </div>
          </button>
          <div className={cn('ml-3', 'mr-4')}>
            <ProductCounter
              isPublished={product.isPublished}
              availableQuantity={product.availableQuantity}
              sku={product.sku}
              attributionToken={attributionToken}
            />
          </div>
        </div>
      </li>
    );
  }
);

ProductSearchSuggestedProduct.displayName = 'ProductSearchSuggestedProduct';
