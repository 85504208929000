import { useTranslation } from 'next-i18next';

import { DELIO_RIDERS_JOIN_WEBSITE } from '@/config/consts';

import { FooterList } from './FooterList';
import { FooterListItem } from './FooterListItem';

export const FooterAboutDelioSection = () => {
  const { t } = useTranslation('layout');
  return (
    <FooterList header={t('About delio')}>
      <FooterListItem url="/about-us">{t('About us')}</FooterListItem>
      <FooterListItem url="/zabka-jush">{t('Meet Zabka Jush')}</FooterListItem>
      <FooterListItem url={DELIO_RIDERS_JOIN_WEBSITE} openInNewTab>
        {t('Become our courier')}
      </FooterListItem>
    </FooterList>
  );
};
