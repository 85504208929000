import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { createCategoryHref } from '@/modules/categories/factories/createCategoryHref';
import { Category } from '@/modules/categories/queries/types';
import { Img } from '@lib/img/components/Img';
import { createImage } from '@lib/sanity/create-image';

export const CategoriesNavContentHeader = ({
  selectedCategory,
  shouldShowImage,
}: {
  selectedCategory?: Category;
  shouldShowImage: boolean;
}) => {
  const { desktopImageUrl } = selectedCategory?.webImage ?? {};

  const image = shouldShowImage
    ? createImage({
        alt: selectedCategory?.name,
        imageHeight,
        imageWidth,
        imageUrl: desktopImageUrl,
      })
    : null;

  return (
    <div
      style={{
        backgroundColor: selectedCategory?.backgroundColor,
        height: imageHeight,
      }}
    >
      {selectedCategory && (
        <div className={cn('flex', 'justify-between')}>
          <NextLink
            href={createCategoryHref({ l0CategoryKey: selectedCategory.key })}
            className={cn('ml-12', 'mt-[18px]', 'mb-4')}
            shallow
            scroll
          >
            <Heading selectedCategory={selectedCategory} isLandingPages />
          </NextLink>

          <div style={{ width: imageWidth, height: imageHeight }}>
            {image && <Img imageFit="contain" {...image} />}
          </div>
        </div>
      )}
    </div>
  );
};

const Heading = ({
  selectedCategory,
  isLandingPages = false,
}: {
  selectedCategory: Category;
  isLandingPages?: boolean;
}) => {
  const { t } = useTranslation('categories');

  return (
    <h4
      className={cn(
        'text-[26px]',
        'leading-[30px]',
        'font-medium',
        'text-gray-900',
        'font-serif',
        !isLandingPages && ['pt-[18px]', 'pb-4', 'pl-12']
      )}
    >
      {selectedCategory.name}
      <span
        className={cn(
          'text-sm',
          'ml-2',
          'font-normal',
          'text-gray-800',
          'font-sans'
        )}
      >
        {t('({{amount}})', {
          amount: selectedCategory.productCount,
          ns: 'categories',
        })}
      </span>
    </h4>
  );
};

const imageWidth = 200;
const imageHeight = 64;
