import { useTranslation } from 'next-i18next';

import { FooterList } from './FooterList';
import { FooterListItem } from './FooterListItem';

export const FooterInformationAndHelpSection = () => {
  const { t } = useTranslation('layout');

  return (
    <FooterList header={t('Information and help')}>
      <FooterListItem url="/faq">{t('FAQ')}</FooterListItem>
      <FooterListItem url="/contact-us">{t('Contact with us')}</FooterListItem>
      <FooterListItem url="/terms-of-service">
        {t('Terms of service')}
      </FooterListItem>
      <FooterListItem
        url="/regulamin-sprzedazy-napojow-alkoholowych.pdf"
        locale={false}
        openInNewTab
      >
        {t('Regulations for the sale of alcoholic beverages')}
      </FooterListItem>
      <FooterListItem
        url="/polityka-prywatnosci.pdf"
        locale={false}
        openInNewTab
      >
        {t('Privacy Policy')}
      </FooterListItem>
    </FooterList>
  );
};
