import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';

import mobile from '@/core/images/mobile.png';
import { cn } from '@/core/ui/utils';

import { AppStoreBadge } from '../../../core/ui/components/AppStoreBadge/AppStoreBadge';
import { GooglePlayBadge } from '../../../core/ui/components/GooglePlayBadge/GooglePlayBadge';

export const FooterMobileApp = () => {
  const { t } = useTranslation('layout');

  return (
    <section className={cn('mt-10', 'md:mt-12', 'lg:mt-16')}>
      <div
        className={cn(
          'bg-decorative-surface-two',
          'flex',
          'pt-8',
          'xl:pt-0',
          'xl:mt-[44px]'
        )}
      >
        <div
          className={cn(
            'mx-auto',
            'max-w-7xl',
            'w-full',
            'px-4',
            'md:flex',
            'md:gap-x-8',
            'xl:p-0',
            'xl:h-[172px]'
          )}
        >
          <div
            className={cn(
              'lg:pb-8',
              'lg:self-center',
              'xl:flex',
              'xl:w-full',
              'xl:pb-0'
            )}
          >
            <div>
              <h4
                className={cn(
                  'pr-2',
                  'text-pretty',
                  'text-heading-small',
                  'font-medium',
                  'text-primary-900',
                  'mb-3',
                  'whitespace-pre-line',
                  'lmd:whitespace-normal',
                  'md:max-w-[330px]'
                )}
              >
                <Trans
                  ns="layout"
                  i18nKey="Do convenient shopping with delio on the <Italic>Żabka Jush</Italic> app!"
                  components={{
                    Italic: <em className={cn('italic', 'font-serif')} />,
                  }}
                />
              </h4>

              <p
                className={cn(
                  'text-balance',
                  'text-body-lg',
                  'text-primary-900',
                  'mb-6',
                  'md:text-pretty',
                  'xl:max-w-[340px]',
                  'xl:mb-0',
                  'min-[1440px]:max-w-full'
                )}
              >
                {t(
                  'Download it now and order daily products with same-day delivery.'
                )}
              </p>
            </div>

            <div
              className={cn(
                'flex',
                'gap-3',
                'mb-5',
                'items-center',
                'md:mb-6',
                'lg:mb-0',
                'xl:ml-auto'
              )}
            >
              <GooglePlayBadge height={40} />
              <AppStoreBadge height={40} />
            </div>
          </div>
          <div
            className={cn(
              'w-full',
              'max-w-[224px]',
              'object-cover',
              'mx-auto',
              'md:-order-1',
              'md:self-end',
              'md:mx-0',
              'xl:max-w-[280px]',
              'xl:relative',
              'xl:h-full'
            )}
          >
            <div className="xl:absolute xl:bottom-0 xl:max-w-[280px]">
              <Image
                src={mobile.src}
                width={mobile.width}
                height={mobile.height}
                quality={100}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
