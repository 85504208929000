import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { Media } from '@lib/theme/components/Media';

import { FooterAboutDelioSection } from './FooterAboutDelioSection';
import { FooterContactSection } from './FooterContactSection';
import { FooterInformationAndHelpSection } from './FooterInformationAndHelpSection';
import { FooterMobileApp } from './FooterMobileApp';
import { FooterSectionHeader } from './FooterSectionHeader';
import { FooterSocialMediaSection } from './FooterSocialMediaSection';

export const Footer = () => {
  const { t } = useTranslation('layout');

  return (
    <>
      <FooterMobileApp />

      <footer
        className={cn(
          'bg-primary-500',
          'w-full',
          'lg:px-4',
          'pb-4',
          'pt-8',
          'lg:pb-16',
          'lg:pt-0'
        )}
      >
        <div className={cn('max-w-7xl', 'mx-auto')}>
          <div className={cn('flex', 'lg:mt-14')}>
            <div
              className={cn(
                'flex',
                'flex-col',
                'lg:flex-row',
                'text-primary-200',
                'justify-between',
                'lg:w-full',
                'w-full'
              )}
            >
              <div
                className={cn(
                  'w-fit',
                  'lg:text-start',
                  'mb-8',
                  'lg:mb-0',
                  'px-4'
                )}
              >
                <FooterContactSection />
                <Media lessThan="lg">
                  <div
                    className={cn(
                      'mt-6',
                      'lg:mt-14',
                      'gap-4',
                      'flex',
                      'flex-col'
                    )}
                  >
                    <FooterSectionHeader>
                      {t('Opening hours')}
                    </FooterSectionHeader>
                    <p className={cn('text-sm', 'font-medium')}>
                      {t('Monday - Sunday 7:00 - 23:00')}
                    </p>
                  </div>
                </Media>
              </div>
              <FooterInformationAndHelpSection />
              <div className={cn('w-full', 'lg:w-auto')}>
                <FooterAboutDelioSection />
                <FooterSocialMediaSection />
              </div>
              <Media greaterThanOrEqual="lg">
                <div className={cn('gap-4', 'flex', 'flex-col')}>
                  <FooterSectionHeader>
                    {t('Opening hours')}
                  </FooterSectionHeader>
                  <p className={cn('text-sm', 'font-medium', 'mt-6')}>
                    {t('Monday - Sunday 7:00 - 23:00')}
                  </p>
                </div>
              </Media>

              {/*
            The following code should be uncommented when the backend implements the newsletter function.
            Then the code in onSubmit should be updated, and the code in opening hours should be removed from there.

            <FooterNewsletterSection />
            */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
