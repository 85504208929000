import { useTranslation } from 'next-i18next';

import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { OldPrice } from '@/core/ui/components/OldPrice/OldPrice';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';

type ProductSearchSuggestedProductPriceProps = {
  discount: Product['price']['discounted'];
  productPrice: Product['price']['value'];
};

export const ProductSearchSuggestedProductPrice = ({
  discount,
  productPrice,
}: ProductSearchSuggestedProductPriceProps) => {
  const { t } = useTranslation('product');

  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'w-18',
        'text-right',
        !discount && 'mt-0.5'
      )}
    >
      <div className={cn('flex', 'flex-col')}>
        <span
          className={cn(
            'text-body-md',
            'font-medium',
            discount ? 'text-primary-400' : 'text-text-default'
          )}
        >
          <FormattedPrice
            centAmount={
              discount ? discount.value.centAmount : productPrice?.centAmount
            }
            currencyCode={
              discount
                ? discount.value.currencyCode
                : productPrice?.currencyCode
            }
          />
        </span>

        {discount && (
          <span className={cn('ml-2', 'text-caption-lg', 'mt-1', 'mb-2')}>
            <OldPrice
              centAmount={productPrice.centAmount}
              currencyCode={productPrice.currencyCode}
            />
          </span>
        )}
      </div>
      {discount && (
        <span className={cn('text-text-subtlest', 'text-caption-sm')}>
          {t('lowest price')}
        </span>
      )}
    </div>
  );
};
