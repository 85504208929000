import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { DELIO_CONTACT_MAIL } from '@/config/consts';
import { cn, showChat } from '@/core/ui/utils';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';

import ChatIcon from '../images/chat-icon.svg';
import Envelope from '../images/envelope-icon.svg';

import { FooterSectionHeader } from './FooterSectionHeader';

export const FooterContactSection = () => {
  const { t } = useTranslation('layout');
  const role = useSession('authRole');
  const isAuthenticated = role === AUTH_ROLE.AUTHENTICATED;

  return (
    <div className={cn('gap-4', 'flex', 'flex-col')}>
      <FooterSectionHeader>{t('Contact')}</FooterSectionHeader>
      <ul className={cn('flex', 'flex-col', 'gap-4', 'font-medium')}>
        <li
          className={cn('flex', 'gap-4', 'items-center', 'text-sm', 'lg:mt-6')}
        >
          <Envelope className={cn('size-4', 'lg:size-6', 'text-primary-200')} />
          <ContactLink url={`mailto:${DELIO_CONTACT_MAIL}`}>
            {DELIO_CONTACT_MAIL}
          </ContactLink>
        </li>
        {isAuthenticated && (
          <li className={cn('flex', 'gap-4', 'items-center', 'text-sm')}>
            <ChatIcon
              className={cn('size-4', 'lg:size-6', 'text-primary-200')}
            />
            <button
              type="button"
              onClick={showChat}
              className={cn('whitespace-nowrap', 'cursor-pointer', 'text-sm')}
            >
              {t('Open chat')}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const ContactLink = ({
  children,
  url,
}: {
  children?: ReactNode;
  url: string;
}) => (
  <span className={cn('whitespace-nowrap')}>
    <Link href={url} className={cn('text-sm')}>
      {children}
    </Link>
  </span>
);
