import NextLink from 'next/link';
import { useContextSelector } from 'use-context-selector';

import { cn } from '@/core/ui/utils';
import { useAnnouncements } from '@/modules/home/hooks/useAnnouncements';

import { NavBarBoxContext } from '../../contexts/NavBarBoxContext';

export const AnnouncementBar = () => {
  const { marketingTopBarRef, navBarRightPosition, navBarPaddingRight } =
    useContextSelector(
      NavBarBoxContext,
      ({ marketingTopBarRef, navBarRightPosition, navBarPaddingRight }) => ({
        marketingTopBarRef,
        navBarRightPosition,
        navBarPaddingRight,
      })
    );

  const { data, isLoading, error } = useAnnouncements();

  if (isLoading) return <Skeleton />;

  if (!data?.announcements.length || error) return null;

  const { description, iconUrl, link } = data.announcements[0] || {};

  return (
    <div
      ref={marketingTopBarRef}
      className={cn('bg-primary-900', 'flex', 'justify-center', 'relative')}
      // Dialogs add padding to html when opened to avoid shift caused by scrollbar disappearing
      // so we need this to counter the background not having full width when cartSidebar is opened
      style={{
        width: navBarRightPosition,
        paddingRight: navBarPaddingRight,
      }}
    >
      {link ? (
        <NextLink href={link} target="_blank">
          <AnnouncementBarContent iconUrl={iconUrl} description={description} />
        </NextLink>
      ) : (
        <AnnouncementBarContent iconUrl={iconUrl} description={description} />
      )}
    </div>
  );
};

const AnnouncementBarContent = ({
  iconUrl,
  description,
}: {
  iconUrl: string | undefined;
  description: string | undefined;
}) => (
  <div
    className={cn(
      'py-2',
      'sm:py-3',
      'px-4',
      'sm:px-12',
      'flex',
      'items-center',
      'justify-center',
      'gap-2',
      'm-auto',
      'text-white',
      'hover:text-primary-100',
      'font-medium'
    )}
  >
    <div className={cn('w-4', 'h-4', 'flex', 'items-center', 'justify-center')}>
      <img
        className={cn('text-primary-400')}
        src={iconUrl}
        width={10}
        height={14}
        alt="announcementIcon"
      />
    </div>
    <p className="text-xs">{description}</p>
  </div>
);

const Skeleton = () => (
  <div
    className={cn('bg-gray-200', 'animate-pulse', 'h-8', 'sm:h-10', 'w-full')}
  />
);
