import { Trans } from 'next-i18next';

import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { ProductBadgesList } from '@/core/ui/components/ProductBadgesList/ProductBadgesList';
import { PromotionBadge } from '@/core/ui/components/PromotionBadge/PromotionBadge';
import { Product } from '@/modules/product/queries/types';
import { PriceDifference } from '@/modules/product/services/checkPriceDifference';

type ProductSearchSuggestedProductBadgesProps = {
  priceDifference: PriceDifference;
  tags?: Product['attributes']['tags'];
};

export const ProductSearchSuggestedProductBadges = ({
  priceDifference,
  tags,
}: ProductSearchSuggestedProductBadgesProps) => (
  <>
    {priceDifference.discountType ? (
      <PromotionBadge>
        {priceDifference.discountType === 'PERCENTAGE' ? (
          <Trans
            ns="product"
            i18nKey="-{{value}}%"
            values={{
              value: Math.ceil(priceDifference.difference),
            }}
          />
        ) : (
          <FormattedPrice
            hideDecimals
            centAmount={priceDifference.difference}
            currencyCode={priceDifference.currencyCode}
          />
        )}
      </PromotionBadge>
    ) : null}
    <ProductBadgesList
      tags={tags}
      direction="row"
      maxNumberOfBadgesOnDesktop={3}
      maxNumberOfBadgesOnMobile={2}
      showOnlyIcon
      slim
    />
  </>
);
