import { ReactNode, useState } from 'react';

import { cn } from '@/core/ui/utils';
import { Media } from '@lib/theme/components/Media';
import ArrowDownIcon from '@lib/theme/svg/arrow-down-icon.svg';
import ArrowUpIcon from '@lib/theme/svg/arrow-up-icon.svg';

import { FooterSectionHeader } from './FooterSectionHeader';

export const FooterList = ({
  children,
  header,
}: {
  children: ReactNode;
  header: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'text-primary-200',
        'w-full',
        'lg:w-auto'
      )}
    >
      <button
        className={cn(
          'border-t',
          'border-primary-200',
          'flex',
          'justify-between',
          'lg:justify-start',
          'items-center',
          'w-full',
          'lg:w-auto',
          'lg:border-0',
          'p-4',
          'lg:p-0'
        )}
        onClick={() => setIsOpen((prev) => !prev)}
        type="button"
      >
        <FooterSectionHeader>{header}</FooterSectionHeader>
        <Media lessThan="lg">
          {isOpen ? (
            <ArrowUpIcon className={arrowClasses} />
          ) : (
            <ArrowDownIcon className={arrowClasses} />
          )}
        </Media>
      </button>
      <Media lessThan="lg">
        {isOpen && (
          <div className={cn('flex', 'flex-col', 'gap-4')}>
            <ul
              className={cn(
                'flex',
                'gap-4',
                'text-sm',
                'flex-col',
                'px-8',
                'last:pb-4'
              )}
            >
              {children}
            </ul>
          </div>
        )}
      </Media>
      <Media greaterThanOrEqual="lg">
        <div className={cn('flex', 'flex-col', 'gap-4', 'mt-10')}>
          <ul
            className={cn('flex', 'gap-4', 'text-sm', 'w-[186px]', 'flex-col')}
          >
            {children}
          </ul>
        </div>
      </Media>
    </div>
  );
};

const arrowClasses = cn('ml-2', 'w-3', 'h-3');
